import { Col, Container, Row } from "react-bootstrap";
import "./MainStoCard.scss";
import bg from "./../../../../images/sto_back.jpg";
import { MobileView } from "react-device-detect";
import { Link } from "react-router-dom";

export const MainStoCard = () => {
  return (
    <div className={"service__logo"} style={{ backgroundImage: `url(${bg})`}}>
      <div className={"service__logo-overlay"}></div>
      <div className={"service__logo-content"}>
        <Container className={"h-100"}>
          <div className={"d-flex flex-column justify-content-between h-100"}>
            <Row className={"g-3 flex-grow-1"}>
              <Col sm={12} md={8} lg={5} className="m-md-0" id={"logo-text"}>
                <div className={" service__logo-header"}>
                  <div>Доступный</div>
                  <div className={"muted"}>автосервис</div>
                </div>
                <div className={"fs-3 mb-px-40"}>Страница в разработке</div>
                <div className={"service__logo-comment"} style={{margin: '0'}}>
                  По вопросам автосервиса, пожалуйста, обратитесь по телефону:
                </div>
                <a
                  className={"service__logo-content_add"}
                  style={{ color: "white", textDecoration: "none" }}
                  href="tel:+78126113015"
                >
                  +7 (812) 611 30 15
                </a>
                <div className={" mt-px-40 service__logo-comment"}>
                  Или напрямую к сотрудникам по адресу автосервиса:
                </div>
                <div className={"service__logo-content_add  mb-px-40"}>
                  СПб, ул. Химиков, 28 лит. Е
                </div>
                <MobileView>
                  <a
                    href="tel:+78126113015"
                    style={{ color: "white", textDecoration: "none", marginBottom: '10px' }}
                  >
                    <button className="call-button">ПОЗВОНИТЬ</button>
                  </a>
                </MobileView>
              </Col>
            </Row>
            <div className={"d-flex justify-content-md-between "} style={{justifyContent: 'center', gap: '10px',  width: '80vw'}}>
              <div className={"footer-contacts-bottom-logo"}>
                <span>ООО Восход</span>
                <span>© 2023 год</span>
              </div>
              <div
                className={"  footer-contacts-bottom-policy "}
                style={{ display: "block" }}
              >
                <Link
                  to={"/offer"}
                  className={
                    "footer-contacts-bottom-element text-white-color me-px-10"
                  }
                >
                  Оферта
                </Link>
                {/* <Link
                  to={"/policy"}
                  className={"footer-contacts-bottom-element text-white-color"}
                >
                  Политика конфиденциальности
                </Link> */}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
