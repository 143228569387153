import React, { Component, ReactNode, useEffect, useState } from "react";
import {
  YMap,
  YMapDefaultSchemeLayer,
  YMapDefaultFeaturesLayer,
  YMapComponentsProvider,
  YMapMarker,
} from "ymap3-components";
import { location as LOCATION, customizationArray } from "./MapHelpers";
import {
  Col,
  Container,
  OverlayTrigger,
  Popover,
  Row,
  Tooltip,
} from "react-bootstrap";
import MapPoint from "./../../../images/index/map-point.png";

export const Map = () => {
  const [location, setLocation] = useState(LOCATION);
  try {
    return (
        <YMap location={location} theme="light">
          <YMapDefaultFeaturesLayer />
          <YMapMarker
            coordinates={[30.473639, 59.969286]}
            onClick={() =>
              window.open("https://yandex.ru/maps/-/CDxFb68Y", "_blank")
            }
          >
            <div className="map_image-container">
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    <strong>АВТОСЕРВИС</strong>
                    <br />
                    Химиков, 28 лит Е
                  </Tooltip>
                }
              >
                <img src={MapPoint} alt="point" className="map_image" />
              </OverlayTrigger>
            </div>
          </YMapMarker>
          <YMapMarker
            coordinates={[30.260111, 59.990724]}
            onClick={() =>
              window.open("https://yandex.ru/maps/-/CDxFfQ09", "_blank")
            }
          >
            <div className="map_image-container">
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    <strong>БЦ “ГУЛЛИВЕР”</strong>
                    <br />
                    Торфяная дорога 7Ф БЦ«Гулливер» 2 оф. 104
                  </Tooltip>
                }
              >
                <img src={MapPoint} alt="point" className="map_image" />
              </OverlayTrigger>
            </div>
          </YMapMarker>
          <YMapMarker
            coordinates={[30.486548, 59.915025]}
            onClick={() => {
              window.open("https://yandex.ru/maps/-/CDxFbWja", "_blank");
            }}
          >
            <div className="map_image-container">
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    <strong>ТЦ “СЛАВЯНСКИЙ БАЗАР”</strong>
                    <br />
                    Товарищеский пр. 20/27, этаж 2, секция 2/9
                  </Tooltip>
                }
              >
                <img src={MapPoint} alt="point" className="map_image" />
              </OverlayTrigger>
            </div>
          </YMapMarker>
          <YMapDefaultSchemeLayer customization={customizationArray} />
        </YMap>
    );
  } catch (e) {
    return (
      <iframe
        className={"map_container"}
        title={"Map"}
        src={process.env.REACT_APP_YMAP_LINK}
        frameBorder="0"
      ></iframe>
    );
  }
};


