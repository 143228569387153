import { Outlet } from "react-router-dom";
import { YMaps } from "@pbe/react-yandex-maps";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "./contexts/AuthContext";
import ScrollToTop from "./utils/ScrollToTop";
import AppToast from "./components/common/AppToast/AppToast";
import { YMapComponentsProvider } from "ymap3-components";

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <YMaps>
          <YMapComponentsProvider
            apiKey={
              process.env.REACT_APP_Y_API_KEY
                ? process.env.REACT_APP_Y_API_KEY
                : "5af93faa-7ddc-4d42-ad93-d93f6b01fd5a"
            }
          >
            <ScrollToTop />
            <Outlet />
            <AppToast />
          </YMapComponentsProvider>
        </YMaps>
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;
